import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { fadeIn } from "../variants";
import Pagination from "./Pagination";
import { SkeletonCustomer } from "./Skeleton";

const Customers = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(15);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getData();
  }, [limit, page, pages]);

  const getData = async () => {
    const response = await axios.get(
      `${apiUrl}/customers?page=${page}&limit=${limit}`
    );
    setData(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
  };

  const filterData = data.filter((item) => item.status === true);

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <motion.div
      variants={fadeIn("up", 0.5)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.2 }}
      className="md:px-14 px-4 max-w-7xl mx-auto"
      id="customers"
    >
      <div className="text-center my-8">
        <h2 className="text-4xl text-neutralDGrey font-semibold mb-2">
          Trusted by Our Happy Customers
        </h2>
        <p className="text-neutralGrey">
          We pride ourselves on our commitment to quality and customer
          satisfaction. We strive to exceed our client's expectations at every
          step of the way, delivering reliable adn sustainable MRO solutions.
        </p>
        {data == 0 ? (
          <SkeletonCustomer />
        ) : (
          <div>
            <div className="my-2 mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {filterData.map((item) => (
                <div key={item.id} className="flex items-center justify-center border border-gray-300 p-1">
                  <img src={item.url} alt={item.title} className="h-14 text-center" />
                </div>
              ))}
            </div>
            <div className="flex gap-2 justify-end">
              <Pagination
                currentPage={page}
                totalPages={pages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Customers;
