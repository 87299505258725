import React, { useEffect, useState } from "react";
import { Chip } from "../../components/Chip";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../../variants";

export const DetailService = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [detailcontent, setDetailContent] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const apiUrl = process.env.REACT_APP_API;

  const { id } = useParams();

  useEffect(() => {
    getSrviceById();
    window.scrollTo(0, 0);
  }, []);

  const getSrviceById = async () => {
    const response = await axios.get(`${apiUrl}/services/${id}`, {
      headers: {
        "Access-Control-Allow-Origin": true,
      },
    });
    setTitle(response.data.title);
    setDescription(response.data.description);
    setDetailContent(response.data.detailcontent);
    setStatus(response.data.status);
    setPreview(response.data.url);
  };

  return (
    <motion.div
      variants={fadeIn("up", 0.3)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.5 }}
      className="ck ck-content"
    >
      <div className="flex flex-col justify-start items-center gap-2 md:my-24 px-4 md:px-48">
        <img src={preview} className="object-cover w-full h-96" />
        <div className="flex gap-10">
          <div className="flex flex-col gap-5">
            <p className="text-2xl font-semibold pt-4">{title}</p>
            {/* <p className="text-2xl font-semibold pt-4">{description}</p> */}
            <p
              className="editor"
              dangerouslySetInnerHTML={{ __html: detailcontent }}
            ></p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
