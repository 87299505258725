import React, { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";

import { motion } from "framer-motion";

import { fadeIn } from "../../variants";
import axios from "axios";
import { Link } from "react-router-dom";
import { SkeletonNews } from "../../components/Skeleton";

const NewsFront = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(8);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getNews();
  }, [limit, page, pages]);

  const getNews = async () => {
    const response = await axios.get(
      `${apiUrl}/news?page=${page}&limit=${limit}`
    );
    setData(response.data.data);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <motion.div
      variants={fadeIn("up", 0.5)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.2 }}
      className="space-y-4 p-10"
    >
      <p className="font-bold text-3xl">Recent Update</p>
      {data == 0 ? (
        <SkeletonNews />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {data.map((project) => (
            <Link key={project.id} to={`/news/detailnews/${project.id}`}>
              <div className="flex flex-col gap-2">
                <div className="relative">
                  <img
                    src={project.url}
                    alt=""
                    className="w-full aspect-square h-36 object-cover rounded-sm z-0"
                  />
                  <span className="absolute right-0 top-0 z-100">
                    {project.status ? (
                      <p className="border px-6 bg-red-600 rounded-bl-full text-white">
                        News
                      </p>
                    ) : (
                      <p className="border px-6 bg-blue-600 rounded-bl-full text-white">
                        Activity
                      </p>
                    )}
                  </span>
                </div>

                <p className="font-semibold text-lg line-clamp-2">
                  {project.headline}
                </p>
                <p
                  className="text-sm text-gray-500 line-clamp-4"
                  dangerouslySetInnerHTML={{ __html: project.description }}
                />
              </div>
            </Link>
          ))}
        </div>
      )}

      {/* <div className="flex gap-2 mx-auto justify-end px-6">
          <div className="flex gap-2">
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={handlePageChange}
            />
          </div>
        </div> */}
    </motion.div>
  );
};

export default NewsFront;
