import React from "react";
import { Mail, PhoneCall } from "lucide-react";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import bg from "../../assets/aboutus.png";

const ContactUs = () => {
  return (
    <div className="flex flex-col gap-10 mx-auto">
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="relative"
      >
        <img
          className="h-96 lg:h-5/6 w-full object-cover rounded-md"
          src={bg}
          alt="Contact Us Background"
        ></img>
        <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
        <div className="absolute inset-0 flex flex-col justify-center p-10">
          <h2 className="text-white text-4xl lg:text-5xl font-bold">
            Contact Us
          </h2>
          <p className="text-white mt-4 md:text-xl lg:text-base xl:text-xl lg:text-left">
            Get in touch with our dedicated team effortlessly. Whether you have
            questions, feedback, or inquiries, we're here to help.
          </p>
        </div>
      </motion.div>

      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex flex-col"
      >
        <div className="max-w-2xl mx-auto text-center my-6">
          <h2 className="text-3xl font-extrabold text-gray-900">Contact us</h2>
          <p className="mt-4 text-lg text-gray-500">
            You can contact the marketing and MCC team directly via:
          </p>
        </div>
        <div className="flex mx-auto gap-10 flex-wrap bg">
          <div className="border border-blue-700 rounded-lg">
            <div className="flex flex-col gap-2 p-6 col-span-4">
              <p className="font-semibold text-blue-700 text-xl">Marketing</p>
              <hr />
              <a href="mailto:jaes.marketing@jas-aero.com" target="_blank">
                <span className="flex gap-4">
                  <Mail className="h-8 w-8 text-blue-600" />
                  <p>jaes.marketing@jas-aero.com</p>
                </span>
              </a>

              <a href="https://wa.me/+6282159585757" target="_blank">
                <span className="flex gap-4">
                  <PhoneCall className="h-8 w-8 text-blue-600" />
                  <p>+62 821-5958-5757</p>
                </span>
              </a>

              <a href="https://wa.me/+6281908333666" target="_blank">
                <span className="flex gap-4">
                  <PhoneCall className="h-8 w-8 text-blue-600" />
                  <p>+62 819-0833-3666</p>
                </span>
              </a>
            </div>
          </div>
          <div className="border border-blue-700 rounded-lg">
            <div className="flex flex-col gap-2 p-6 ">
              <p className="font-semibold text-blue-600 text-xl">
                Maintenance Control Center
              </p>
              <hr />
              <a href="mailto:mcc@jas-aero.com" target="_blank">
                <span className="flex gap-4">
                  <Mail className="h-8 w-8 text-blue-600" />
                  <p>mcc@jas-aero.com</p>
                </span>
              </a>

              <a href="https://wa.me/+6282180822266" target="_blank">
                <span className="flex gap-4">
                  <PhoneCall className="h-8 w-8 text-blue-600" />
                  <p>+62 821-8082-2266</p>
                </span>
              </a>

              <a href="https://wa.me/+628111220502" target="_blank">
                <span className="flex gap-4">
                  <PhoneCall className="h-8 w-8 text-blue-600" />
                  <p>+62 811-1220-502</p>
                </span>
              </a>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.section
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className=" md:p-10"
      >
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-8 lg:px-8">
          <div className="max-w-2xl  mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Visit Us</h2>
            <p className="mt-4 text-lg text-gray-500">
              Visit our office for innovation, expertise, and a client-focused
              approach. We offer a modern, collaborative space in a convenient
              location. We're excited to welcome you for a productive and
              professional experience.
            </p>
          </div>
          <div className="mt-16 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="rounded-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31737.22867907226!2d106.6529917572684!3d-6.110013940595832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a0301cc3e284f%3A0x2a42fd3115f00435!2sPT%20JAS%20Aero%20Engineering%20Services!5e0!3m2!1sid!2sid!4v1699414815246!5m2!1sid!2sid"
                  width="600"
                  height="450"
                  loading="lazy"
                ></iframe>
              </div>
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="p-4">
                    <p className="font-bold text-gray-600 text-xl mb-2">
                      Office Address
                    </p>
                    <hr className="mb-2" />
                    <p className="text-gray-600">
                      PT JAS Aero Engineering Services
                    </p>
                    <p className="text-gray-600">
                      Head Office - Kota Tangerang
                    </p>
                    <p className="text-gray-600">
                      Priskila Tower, Cengkareng Business City Lot 5
                    </p>
                    <p className="text-gray-600">Tower P lt. 7 unit 06 & 07</p>
                    <p className="text-gray-600">Jl. Atang Sanjaya No. 21</p>
                    <p className="text-gray-600">
                      Kelurahan Benda, Kecamatan Benda
                    </p>
                    <p className="text-gray-600">
                      Tangerang, Banten - Indonesia 15125
                    </p>
                    <p className="text-gray-600">+622 1387 64021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};
export default ContactUs;
