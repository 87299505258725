import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Dashboard } from "./pages/dashboard/dashboard";
import Services from "./pages/service/service";
import News from "./pages/news/news";
import Career from "./pages/career/Career";
import ContactUs from "./pages/contactus/contactus";
import { DetailNews } from "./pages/news/detailnews";
import { DetailService } from "./pages/service/detailservice";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/navigation/Footer";
import About from "./pages/about/About";
import { DetailAuthorities } from "./pages/authorities/detailauthorities";
import { DetailCareer } from "./pages/career/detailcareer";

function App({ children }) {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/service/detailservice/:id" element={<DetailService />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/detailnews/:id" element={<DetailNews />} />
        <Route path="/career/detailcareer/:id" element={<DetailCareer />} />
        <Route path="/career" element={<Career />} />
        <Route
          path="/authorities/detailauthorities/:id"
          element={<DetailAuthorities />}
        />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
