"use client";
import BackgroundImage from "./BackgroundImage";
import Slides from "./Slides";
import SlideInfo from "./SlideInfo";
import Controls from "./Controls";
import { AnimatePresence } from "framer-motion";
import React from "react";

import banner from "../../assets/aboutus.png";
import banner1 from "../../assets/cokfit3.jpeg";
import banner2 from "../../assets/authoritiess.jpg";
import banner3 from "../../assets/clientair.jpg";

export type Data = {
  img: string;
  title: string;
  description: string;
  location: string;
};

export type CurrentSlideData = {
  data: Data;
  index: number;
};

export const Hero = () => {
  const [data, setData] = React.useState<Data[]>(sliderData.slice(1));
  const [transitionData, setTransitionData] = React.useState<Data>(
    sliderData[0]
  );
  const [currentSlideData, setCurrentSlideData] =
    React.useState<CurrentSlideData>({
      data: initData,
      index: 0,
    });

  return (
    <main
      className={`
    
        relative min-h-screen select-none overflow-hidden text-white antialiased`}
    >
      <AnimatePresence>
        <BackgroundImage
          transitionData={transitionData}
          currentSlideData={currentSlideData}
        />
        <div
          key={currentSlideData.index}
          className="  absolute z-20  h-full w-full"
        >
          <div className=" flex h-full w-full grid-cols-10 flex-col md:grid">
            <div className=" col-span-4 mb-3 flex h-full flex-1 flex-col justify-end px-5 md:mb-0 md:justify-center md:px-10">
              <SlideInfo
                transitionData={transitionData}
                currentSlideData={currentSlideData}
              />
            </div>
            <div className=" col-span-6 flex h-full flex-1 flex-col justify-start p-4 md:justify-center md:p-10">
              <Slides data={data} />
              <Controls
                currentSlideData={currentSlideData}
                data={data}
                transitionData={transitionData}
                initData={initData}
                handleData={setData}
                handleTransitionData={setTransitionData}
                handleCurrentSlideData={setCurrentSlideData}
                sliderData={sliderData}
              />
            </div>
          </div>
        </div>
      </AnimatePresence>
    </main>
  );
};

const sliderData = [
  {
    img: banner,
    location: "About Us",
    description:
      "Maintenance, Repair and Overhaul (MRO) - Joint venture company between PT Cardig Aero Services and Singapore Airlines Engineering Company providing Line Maintenance services including regular maintenance check up to A Check for domestic and International Aircraft in Indonesia with more than twenty years of experiences.",
    title: "JAS Aero Engineering",
  },

  {
    img: banner1,
    location: "This is",
    description:
      "Providing Line Maintenance and Technical Ramp Handling Services which include Certification, Defect Rectification, Ground Support Equipment (GSE), and other supplementary services to more than 40 airlines from Europe, USA, Middle East, Australia and Asia at 17 airports in Indonesia.",
    title: "Our Services",
  },
  {
    img: banner2,
    location: "Our capabilities are approved by",
    description:
      "Discover the vital role of aviation authorities in regulating and ensuring the safety and efficiency of air travel",
    title: "18 Aviation Authorities",
  },
  {
    img: banner3,
    location: "Trusted by",
    description: "We serve many of the best airlines from all over the world",
    title: "Our Happy Customers",
  },
];

const initData = sliderData[0];
