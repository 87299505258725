import React from "react";
import aboutImg from "../assets/ourpeople.jpeg";
import Maecenas from "../assets/maecenas.png";

// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
import { Link } from "react-router-dom";

const AboutUsCompany = () => {
  return (
    <div className="my-12" id="product">
      <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between">
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
          >
            <img src={aboutImg} alt="" className="w-fit rounded-lg" />
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="md:w-3/5 mx-auto px-4"
          >
            <h2 className="text-4xl text-neutralDGrey font-semibold mb-4 md:w-4/5">
              About Us
            </h2>
            <p className="md:w-3/4 text-sm text-neutralGrey mb-8">
              PT JAS Aero Engineering Services (JAE) is a MRO company providing
              Line Maintenance and Technical Ramp Handling Services which
              include Certification, Defect Rectification, Ground Support
              Equipment (GSE), and other supplementary services to more than 40
              airlines from Europe, USA, Middle East, Australia and Asia at 17
              Airports in Indonesia.
            </p>
            <Link to={"/about"}>
              <button className="rounded-md bg-indigo-600 text-white p-2">
                Learn More
              </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsCompany;
