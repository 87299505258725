import React from "react";
// motion
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

export const Skeleton = () => {
  return (
    <motion.div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: "some" }}
      className="my-4 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mx-auto gap-12"
    >
      {Array.from({ length: 12 }).map((_, index) => (
        <SkeletonAuthorities key={index} />
      ))}
    </motion.div>
  );
};

export const SkeletonAuthorities = () => {
  return (
    <div className="shadow rounded-md mt-10 max-w-sm w-full mx-auto">
      <div className="animate-pulse px-2 md:px-4 py-8 text-center w-80 md:w-60 mx-auto md:h-48 rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all duration-300 flex items-center justify-center h-full border border-indigo-700">
        <div className="flex flex-col">
          <div className="w-36 mx-auto mb-4 bg-slate-600 rounded">
            <div className="w-36 h-36" />
          </div>

          <h4 className="text-lg font-bold h-4 bg-slate-600 rounded uppercase mb-2 px-2"></h4>
        </div>
      </div>
    </div>
  );
};

export const SkeletonCustomer = () => {
  return (
    <div className="flex flex-col">
      <div className="my-2 mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8">
        {Array.from({ length: 15 }).map((_, index) => (
          <ShimmerCustomers key={index} />
        ))}
      </div>
      <div className="flex gap-2 justify-end">
        {Array.from({ length: 5 }).map((_, index) => (
          <ShimmerPage key={index} />
        ))}
      </div>
    </div>
  );
};

export const SkeletonNews = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {Array.from({ length: 12 }).map((_, index) => (
          <ShimmerNews key={index} />
        ))}
      </div>

      <div className="flex gap-2 justify-end">
        {Array.from({ length: 5 }).map((_, index) => (
          <ShimmerPage key={index} />
        ))}
      </div>
    </div>
  );
};

export const ShimmerPage = () => {
  return (
    <div className="animate-pulse">
      <button className="w-8 h-8 rounded-md bg-gray-600"></button>
    </div>
  );
};

export const ShimmerCustomers = () => {
  return (
    <div className="animate-pulse px-2 md:px-4 py-8 text-center w-80 md:w-60 mx-auto md:h-24  flex items-center justify-center ">
      <div className=" animate-pulse w-60 h-20 bg-slate-600"></div>
    </div>
  );
};

export const ShimmerNews = () => {
  return (
    <div className="animate-pulse flex flex-col gap-2">
      <div className="w-full h-24 bg-slate-600 rounded-sm" />

      <div className="h-4 w-48 rounded-md bg-gray-600" />
      <div className="h-4 w-full rounded-md bg-gray-600" />
      <div className="h-4 w-full rounded-md bg-gray-600" />
    </div>
  );
};
