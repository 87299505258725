import React from "react";
import aboutImg from "../../assets/aboutus.png";
import structure from "../../assets/struktur.png";
import visimisi from "../../assets/vision-mission-values.png";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../../variants";
import NewsSlide from "../../components/newsslide/NewsSlide";
import { FaBullseye, FaEye } from "react-icons/fa6";
import Authorities from "../../components/Authorities";
import { Gallery, ImageGallery } from "../../components/Gallery";

const About = () => {
  const coreCompany = [
    {
      title: "Care",
      description:
        "We care about our customers, and will seek to understand the customer first, then develop solutions for their needs.",
    },
    {
      title: "Accurate & Responsive",
      description:
        "Customer focus facilitates delivery of effective, accurate solutions. We are always responsive to customer feedback.",
    },
    {
      title: "Strive",
      description:
        "In our work we will consistently strive to be the best in the industry",
    },
    {
      title: "Quality",
      description:
        "Our products and services qualities should exceed customer expectationss",
    },
    {
      title: "People Development",
      description:
        "We must consistently invest in our people as our most valuable resource",
    },
    {
      title: "Driving Execution",
      description:
        "Focus, detailed and systematic action in order to achieve objectives thoroughly",
    },
    {
      title: "Respect",
      description:
        "We honor and respect our customers, companies, stakeholders, and each other",
    },
    {
      title: "Integrity",
      description: "We are professionals with a high level of integrity",
    },
    {
      title: "Meritocratic",
      description:
        "We are meritocratic organization that values competencies, performances, and contribution to CAS Group",
    },
  ];
  return (
    <div>
      <NewsSlide />
      <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto mt-20" id="about">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.5 }}
            className="md:w-4/5 mx-auto"
          >
            <h2 className="text-3xl text-neutralDGrey font-semibold mb-4 md:w-4/5">
              About Us
            </h2>
            <p className="text-sm text-neutralGrey mb-4">
              PT JAS Aero Engineering Services (JAE) is a MRO company providing
              Line Maintenance and Technical Ramp Handling Services which
              include Certification, Defect Rectification, Ground Support
              Equipment (GSE), and other supplementary services to more than 40
              airlines from Europe, USA, Middle East, Australia and Asia at 17
              Airports in Indonesia.
            </p>
            <p className=" text-sm text-neutralGrey mb-4">
              JAE is a joint venture company between PT Cardig Aero Services,
              Tbk (51%), and Singapore Airlines Engineering Company (49%) which
              started operations in December 2003. JAE is approved by Indonesia
              DGCA, and other several notable Aviation Authorities including
              CASA, FAA, CAAS. Our certification capabilities cover many Airbus
              and Boeing aircraft with key engine types including the largest
              A380 aircraft and the most advanced Aircraft, the A350 and B787.
            </p>
            <p className="text-sm text-neutralGrey mb-4">
              Our services are also complemented by Wheels and Brake services to
              support the aviation business in Indonesia.
            </p>
          </motion.div>

          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
          >
            <img
              src={aboutImg}
              alt=""
              className="w-full h-48 lg:h-72 rounded-md"
            />
          </motion.div>
        </div>
      </div>

      <div className="px-4 max-w-6xl mx-auto mt-10" id="about">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row gap-12 p-4">
          <motion.div
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.5 }}
            className="md:w-4/5 mx-auto"
          >
            <h2 className="text-3xl  font-semibold mb-4 md:w-4/5">
              Video Company Profile
            </h2>
            <div>
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/UY2sLTZzRTM?si=zPz9d_dzmnXMopwG"
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* <Gallery /> */}

      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="px-4 lg:px-20 max-w-screen-2xl mx-auto  my-8 py-12"
      >
        <h1 className="text-3xl font-bold my-4">Our vission & Mission</h1>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
            <div className="flex justify-center items-center mx-auto px-8">
              <img src={visimisi} className="w-72" alt="" />
            </div>
            <div className="flex flex-col w-full lg:w-8/12 gap-4">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="p-4 bg-blue-900 text-white"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FaEye className="mr-2" />
                  Vission
                </h2>
                <p>
                  To be the best preferred aircraft maintenance service company
                  and aircraft supporting provider in Indonesia for domestic and
                  international airlines.
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="p-4  bg-blue-900 text-white"
              >
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                  <FaBullseye className="mr-2" />
                  Mission
                </h2>
                <p>
                  To deliver the highest quality standard and consistency of
                  service for customer satisfaction.
                </p>
              </motion.div>
            </div>
          </div>

          <div>
            <h1 className="text-3xl font-bold pt-4 pb-8">Company Value</h1>
            <div className="grid gap-8 gap-y-12 lg:grid-cols-3">
              {coreCompany.map((item) => (
                <div
                  key={item.title}
                  className=" relative flex flex-col px-2 md:px-4 py-8  mx-auto  rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all duration-300  h-full border border-indigo-700"
                >
                  {/* <div className="absolute right-0 bottom-0">
                    <span className="bg-red-500 border-4 border-dashed w-48 h-72">
                      Our Value
                    </span>
                  </div> */}
                  <p className="font-semibold text-lg">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="px-4 lg:px-20 max-w-screen-2xl mx-auto  my-8 py-12"
      >
        <h1 className="text-3xl font-bold my-4 text-center">
          Organizational Structure
        </h1>

        <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
          <img src={structure} alt="Struktur Organisasi" />
        </div>
      </motion.div>

      {/* <Authorities /> */}
    </div>
  );
};

export default About;
