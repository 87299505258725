import React, { useEffect, useState } from "react";
// motion
import { motion } from "framer-motion";
import { Carousel } from "flowbite-react";
import { fadeIn } from "../../variants";
import axios from "axios";
import { Link } from "react-router-dom";
import bg from "../../assets/cokfit3.jpeg";
import jaestation from "../../assets/peta-jaes.png";
import { dataAirport } from "../../assets/airport";

const Services = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(12);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getServices();
  }, [limit, page, pages]);

  const getServices = async () => {
    const response = await axios.get(
      `${apiUrl}/services?page=${page}&limit=${limit}`
    );
    setData(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
  };

  return (
    <div>
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}
        className="relative"
      >
        <img
          className="max-h-96 mt-20 w-full object-cover rounded-md"
          src={bg}
          alt="Service"
        ></img>
        <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
        <div className="absolute inset-0 flex flex-col justify-center p-10">
          <h1 className="text-white text-4xl lg:text-7xl font-bold">
            Our Services
          </h1>
        </div>
      </motion.div>
      <div className="flex flex-col text-center mx-auto my-12 px-4">
        <div className="flex flex-col mx-auto max-w-4xl text-center">
          <h2 className="text-4xl text-neutralDGrey font-semibold mb-3">
            Our Services
          </h2>
          <p className="text-neutralGrey text-center">
            With the support of our trained engineers and mechanics, we provide
            high quality service to make sure the aircraft is in complience and
            safety condition before take off.
          </p>
        </div>

        <div className="m-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:w-11/12 mx-auto gap-12">
          {data.map((service) => (
            <Link key={service.id} to={`/service/detailservice/${service.id}`}>
              <div
                key={service.id}
                className="text-center py-4 shadow md:w-[300px] mx-auto cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all duration-300 flex flex-col items-center justify-center h-90"
              >
                <div className="w-full mx-auto">
                  <img
                    src={service.url}
                    alt=""
                    className="h-36 w-full object-cover rounded-sm "
                  />
                </div>
                <h4 className="text-xl font-bold text-neutralDGrey mb-2 px-2">
                  {service.title}
                </h4>
                <p
                  className="text-sm text-neutralGrey line-clamp-3"
                  dangerouslySetInnerHTML={{ __html: service.description }}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="flex flex-col text-center max-w-6xl mx-auto my-12 px-4">
        <div className="flex flex-col lg:w-8/12 text-center mx-auto">
          <h2 className="text-4xl text-neutralDGrey font-semibold mb-3">
            Our Networks
          </h2>
          <p className="text-neutralGrey">
            Welcome to our Aircraft Maintenance Service Station, where we ensure
            your flights are not just safe but extraordinary. With unmatched
            dedication and expertise, we redefine aviation excellence
          </p>
          <img
            className="mt-12 w-full object-cover rounded-md"
            src={jaestation}
            alt="Jae Station"
          ></img>
        </div>

        <br />

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {dataAirport.map((item) => (
            <Link key={item.no} to={item.url} target="_blank">
              <div
                key={item.no}
                className="relative h-52  shadow cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all duration-300"
              >
                <img
                  className="w-full object-cover h-36 rounded-sm"
                  src={item.image}
                  alt="Jae Station"
                ></img>
                <span className="absolute left-0 top-0 border text-white px-4 py-2 bg-indigo-600 rounded-r-full">
                  {item.code}
                </span>
                <span className="absolute right-0 top-28 border px-4 py-1 text-white bg-indigo-600 rounded-tl-full">
                  {item.kota}
                </span>
                <p className="p-2">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
