import { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";

import banner from "../../assets/aboutus.png";
import banner1 from "../../assets/cokfit3.jpeg";
import banner2 from "../../assets/authoritiess.jpg";

import arrowRight from "../../assets/icons/click.png";

const NewsSlide = () => {
  const [bigShoeImg, setBigShoeImg] = useState(banner);

  const shoes = [
    {
      thumbnail: banner,
      bigShoe: banner,
    },
    {
      thumbnail: banner2,
      bigShoe: banner2,
    },
    {
      thumbnail: banner1,
      bigShoe: banner1,
    },
  ];

  return (
    <motion.div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.7 }}
      className="relative"
    >
      <img
        className="h-96 lg:h-5/6 w-full object-cover rounded-md"
        src={banner}
        alt="Contact Us Background"
      ></img>
      <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
      <div className="absolute inset-0 flex flex-col justify-center p-10">
        <h1 className="text-white text-4xl lg:text-7xl font-bold">
          PT JAS Aero
        </h1>
        <h1 className="text-white text-4xl lg:text-7xl font-bold">
          Engineering Services
        </h1>
        <p className="text-white mt-4 text-center md:text-xl lg:text-base xl:text-xl lg:text-left">
          Safety and Quality is Our Priority
        </p>
      </div>
    </motion.div>
  );
};

export default NewsSlide;
