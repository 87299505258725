import React from "react";
import HeroSlider from "../../components/Hero";
import AboutUsCompany from "../../components/Company";
import Customers from "../../components/Customer";
import Authorities from "../../components/Authorities";
import NewsFront from "../news/newsFront";

export const Dashboard = () => {
  return (
    <div className="z-0">
      <HeroSlider />
      <AboutUsCompany />
      <NewsFront />
      <Customers />
      <Authorities />
    </div>
  );
};
