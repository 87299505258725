import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../../variants";
import { Chip } from "../../components/Chip";
import moment from "moment";

export const DetailCareer = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [closingdate, setClosingDate] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const apiUrl = process.env.REACT_APP_API;

  const { id } = useParams();

  useEffect(() => {
    getCareerById();
    window.scrollTo(0, 0);
  }, []);

  const getCareerById = async () => {
    const response = await axios.get(`${apiUrl}/career/${id}`);
    setTitle(response.data.title);
    setDescription(response.data.description);
    setClosingDate(response.data.closingdate);
    setStatus(response.data.status);
    setPreview(response.data.url);
  };

  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <motion.div
      variants={fadeIn("up", 0.3)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.5 }}
      className="ck ck-content"
    >
      <div className="flex flex-col justify-start gap-2 px-4 mb-12 md:px-48">
        <img
          src={preview}
          className="w-full lg:h-96 mt-24 lg:mt-36" alt="career alt"
        />
        <div className="flex gap-10 p-4">
          <div className="flex flex-col w-full mx-auto gap-5">
            <p className="text-2xl lg:text-4xl font-semibold pt-4">{title}</p>
            <Chip
              title={formatClosing(closingdate)}
              className="bg-green-700 w-1/3"
            />
            <p
              className="editor"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
