import { Data } from "./hero";
import SliderCard from "./SliderCard";

type Props = {
  data: Data[];
};
const index = 1;

function Slides({ data }: Props) {
  return (
    <div className="flex w-full gap-6">
      {data.map((data) => {
        return <SliderCard key={data.description} data={data} />;
      })}
    </div>
  );
}

export default Slides;
