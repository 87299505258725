import React, { useEffect, useState } from "react";
import { Chip } from "../../components/Chip";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../../variants";

export const DetailNews = () => {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [volno, setVolno] = useState("");
  const [newsslug, setNewsSlug] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [image, setImage] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const apiUrl = process.env.REACT_APP_API;

  const { id } = useParams();

  useEffect(() => {
    getNewsById();
    window.scrollTo(0, 0);
  }, []);

  const getNewsById = async () => {
    const response = await axios.get(`${apiUrl}/news/${id}`);
    setHeadline(response.data.headline);
    setDescription(response.data.description);
    setVolno(response.data.volno);
    setDatetime(response.data.datetime);
    setStatus(response.data.status);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <div className="ck ck-content">
      <div className="flex flex-col justify-start items-center gap-2 md:my-24 px-4 md:px-48">
        <img
          src={preview}
          className="object-cover w-full h-48 md:h-full mt-24 md:mt-12"
        />

        <div className="flex gap-10">
          <div className="flex flex-col gap-5">
            <p className="text-2xl font-semibold pt-4">{headline}</p>
            <div className="flex md:flex-row gap-2">
              {status === true ? (
                <Chip title="News" className="bg-red-700 w-36 text-center" />
              ) : (
                <Chip
                  title="Activity"
                  className="bg-blue-700 w-36 text-center"
                />
              )}

              <Chip
                title={formatClosing(datetime)}
                className="bg-green-700 w-52 text-center"
              />
            </div>

            <p
              className="editor"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};
