import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import careerImage from "../../assets/aboutus.png";
import bg from "../../assets/hiring.jpg";
import onlineCV from "../../assets/onlinecv.svg";
import reviewCV from "../../assets/reviewcv.svg";
import interview from "../../assets/interview.svg";
import test from "../../assets/test.svg";
import reference from "../../assets/reference.svg";
import joboffer from "../../assets/joboffer.svg";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";

const Career = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(8);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getNews();
  },  [limit, page, pages]);

  const getNews = async () => {
    const response = await axios.get(`${apiUrl}/career?page=${page}&limit=${limit}`);
    setData(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const filtered_array = data.filter((item) => item.status === true);

  const career = [
    {
      title: "Step 1: Apply Online",
      description:
        "Fill out our online application form with your personal and professional details.",
      image: onlineCV,
    },
    {
      title: "Step 2: Resume Review",
      description:
        "Our HR team will review your resume and assess your qualifications.",
      image: reviewCV,
    },
    {
      title: "Step 3: Interview",
      description:
        "If selected, ,you'll be invited for an interview with our hiring manager.",
      image: interview,
    },
    {
      title: "Step 4: Assessment",
      description:
        "Depending on the role, you may need to complete a skills assessment or task.",
      image: test,
    },
    {
      title: "Step 5: Reference Check",
      description:
        "We'll conduct reference checks to verify your previous work experience.",
      image: reference,
    },
    {
      title: "Step 6: Offer",
      description:
        "Congratulations! If all goes well, you'll receive a job offer from us.",
      image: joboffer,
    },
  ];

  return (
    <motion.div
      // variants={fadeIn("up", 0.3)}
      // initial="hidden"
      // whileInView={"show"}
      // viewport={{ once: false, amount: 0.2 }}
      className="relative flex flex-col"
    >
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="relative"
      >
        <img
          className="max-h-96 mt-20 w-full object-cover rounded-md"
          src={bg}
          alt="Service"
        ></img>
        <div className="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
        <div className="absolute inset-0 flex flex-col justify-center p-10">
          <h1 className="text-white text-3xl lg:text-7xl font-bold">
            Let's join with us
          </h1>
          <p className="text-white mt-4 text-center md:text-xl lg:text-base xl:text-xl lg:text-left">
            Discover your path to success and start your career journey now!
          </p>
        </div>
      </motion.div>
      <div className="p-6 lg:m-10">
        <h1 className="text-3xl font-semibold mb-6">Join Our Team</h1>
        <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {career.map((item) => (
            <div
              key={item.title}
              className="flex flex-col items-center p-4 lg:p-12 justify-between text-center  border-lightText md:border-none cursor-pointer rounded-lg hover:shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:text-white hover:bg-sky-900 transition-all"
            >
              <div className="flex h-52">
                <img
                  src={item.image}
                  className="w-full rounded-2xl object-fill"
                  alt="img"
                />
              </div>
              <div>
                <h3 className="font-bold text-xl selection:my-5">
                  {item.title}
                </h3>
                <p
                  className="line-clamp-3 text-base mb-8"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </motion.div>
      </div>

      <motion.div
        // variants={fadeIn("left", 0.3)}
        // initial="hidden"
        // whileInView={"show"}
        // viewport={{ once: false, amount: 0.2 }}
        className="p-6 lg:m-10"
      >
        <p className="font-bold text-3xl pb-4">Job Vacancies</p>
        {filtered_array.length === 0 ? (
          <div className="flex">
            <span className="border border-red-700 p-4">
              <p className="text-lg text-red-700">
                There are no job vacancies at this time
              </p>
            </span>
          </div>
        ) : (
          <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {filtered_array.map((item) => (
              <Link key={item.id} to={`/career/detailcareer/${item.id}`}>
                <div key={item.id} className="flex flex-col gap-2">
                  <img
                    src={item.url}
                    className="w-full rounded-2xl object-fill"
                    alt="img"
                  />

                  <div>
                    <p className="font-semibold text-lg line-clamp-2">
                      {item.title}
                    </p>
                    <p
                      className="text-neutralGrey line-clamp-3 text-base mb-8"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
              <div className="flex gap-2 justify-end pb-4">
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onPageChange={handlePageChange}
                />
          </div>
          </div>

        )}
      </motion.div>
    </motion.div>
  );
};

export default Career;
