import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { ShimmerNews, SkeletonNews } from "../../components/Skeleton";

const News = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(12);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getNews();
  }, [limit, page, pages]);

  const getNews = async () => {
    const response = await axios.get(
      `${apiUrl}/news?page=${page}&limit=${limit}`
    );
    setData(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
  };

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
    scrollToTop();
  };

  return (
    <div id="news">
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="px-4 lg:px-14 w-full mx-auto min-h-screen h-screen flex justify-center items-center"
      >
        <Carousel className="w-full mx-auto">
          {data.map((item, index) => (
            <div
              key={item.id}
              className="my-12 md:my-8 py-12 flex flex-col w-full mx-auto md:flex-row-reverse items-center justify-between gap-4"
            >
              <div className="flex w-full md:w-3/5 justify-center items-center mx-auto">
                <img
                  src={item.url}
                  className="w-full h-48 md:h-96 rounded-lg object-cover"
                  alt={item.headline}
                />
              </div>
              <div className="md:w-1/2">
                <h1 className="text-xl md:text-4xl mb-1 md:mb-4 font-semibold text-neutralDGrey line-clamp-2 md:line-clamp-3">
                  {item.headline}
                </h1>
                <p
                  className="text-neutralGrey line-clamp-2 md:line-clamp-3 text-base mb-8"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />

                <Link to={`/news/detailnews/${item.id}`}>
                  <button className="px-7 py-2 bg-brandPrimary text-white rounded hover:bg-neutralDGrey">
                    Read Detail
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      </motion.div>

      <motion.div
        // variants={fadeIn("up", 0.2)}
        // initial="hidden"
        // whileInView={"show"}
        // viewport={{ once: false, amount: 0.5 }}
        className="space-y-4 p-10"
      >
        <p className="font-bold text-3xl">Recent Update</p>
        {data == 0 ? (
          <SkeletonNews />
        ) : (
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {data.map((project) => (
                <Link key={project.id} to={`/news/detailnews/${project.id}`}>
                  <div key={project.id} className="flex flex-col gap-2">
                    <div className="relative">
                      <img
                        src={project.url}
                        alt=""
                        className="w-full aspect-square h-36 object-cover rounded-sm z-0"
                      />
                      <span className="absolute right-0 top-0 z-0">
                        {project.status ? (
                          <p className="border px-6 bg-red-600 rounded-bl-full text-white">
                            News
                          </p>
                        ) : (
                          <p className="border px-6 bg-blue-600 rounded-bl-full text-white">
                            Activity
                          </p>
                        )}
                      </span>
                    </div>

                    <p className="font-semibold text-lg line-clamp-2">
                      {project.headline}
                    </p>
                    <p
                      className="text-neutralGrey line-clamp-3 text-base mb-8"
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div className="flex gap-2 mx-auto justify-end px-6">
              <div className="flex gap-2">
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onPageChange={handlePageChange}
                />
              </div>
              {/* <RowsPagination page={page} pages={pages} /> */}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default News;
