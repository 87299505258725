import React, { useEffect, useState } from "react";
// motion
import { motion } from "framer-motion";
import axios from "axios";
// variants
import { fadeIn } from "../variants";
import { Skeleton } from "./Skeleton";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

const Authorities = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(6);
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getAuthorities();
  }, [limit, page, pages]);

  const getAuthorities = async () => {
    const response = await axios.get(`${apiUrl}/authorities?page=${page}&limit=${limit}`);
    setData(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="px-4 max-w-6xl mx-auto" id="authorities">
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="text-center mx-auto"
      >
        <h2 className="text-4xl text-neutralDGrey font-semibold mb-3">
          Approval Authorities
        </h2>
        <p className="text-neutralGrey">
          Authorities in aircraft maintenance are organizations responsible for
          overseeing and regulating the maintenance, safety, and airworthiness
          of aircraft. They enforce rules, standards, and maintenance procedures
          to ensure that aircraft are in proper working condition, thus
          enhancing aviation safety.
        </p>
      </motion.div>

      {data == 0 ? (
        <Skeleton />
      ) : (
        <div>
 <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: "some" }}
          className="my-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mx-auto gap-12"
        >
          {data.map((authorities) => (
            <Link
              key={authorities.id}
              to={`/authorities/detailauthorities/${authorities.id}`}
            >
              <div
                key={authorities.id}
                className="px-2 flex-col md:px-4 py-8 text-center w-80 md:w-60 mx-auto md:h-80 rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all duration-300 flex items-center justify-center h-full border border-indigo-700"
              >
                <img src={authorities.url} alt="" className="px-2" />

                <h4 className="text-lg font-bold text-neutralDGrey uppercase mb-2 px-2">
                  {authorities.country}
                </h4>
              </div>
            </Link>

          ))}
        </motion.div>
          <div className="flex gap-2 justify-end pb-4">
                <Pagination
                  currentPage={page}
                  totalPages={pages}
                  onPageChange={handlePageChange}
                />
          </div>
        </div>

      )}
    </div>
  );
};

export default Authorities;
