import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../../variants";

export const DetailAuthorities = () => {
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const apiUrl = process.env.REACT_APP_API;

  const { id } = useParams();

  useEffect(() => {
    getAuthoritiesById();
    window.scrollTo(0, 0);
  },);

  const getAuthoritiesById = async () => {
    const response = await axios.get(`${apiUrl}/authorities/${id}`);
    setTitle(response.data.title);
    setCountry(response.data.country);
    setStatus(response.data.status);
    setPreview(response.data.url);
  };

  return (
    <motion.div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.2 }}
      className="ck ck-content"
    >
      <div className="flex flex-col justify-center items-center gap-2 mt-24 md:px-48">
        <p className="text-2xl lg:text-4xl font-bold">{title}</p>
        <p className="text-2xl lg:text-3xl font-semibold">{country}</p>
        <img src={preview} className="w-full h-full object-fill" alt="" />
      </div>
    </motion.div>
  );
};
